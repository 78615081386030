import { SearchResponse } from '@algolia/client-search';
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { UtilsHelper } from '@commons/utils/utils';
import { environment } from '@environment';
import { Profession, ProfessionAlgolia } from '@models/profession/profession';
import {
  PROFESSIONS_BUSINESS_LINE,
  PROFESSION_ACCESS_LEVEL,
  PROFESSION_INTERESTS,
  ProfessionAccessLevelEnum,
  ProfessionBusinessLineEnum,
  ProfessionInterestsEnum,
} from '@models/profession/professions-filters';
import { AlgoliaClientProvider } from '@wizbii-utils/angular/algolia';
import { SearchParamsObject } from 'algoliasearch';
import { Observable, from, map, switchMap } from 'rxjs';

type RecordWithStringKeys<T> = Record<Extract<keyof T, string>, string>;

@Injectable({
  providedIn: 'root',
})
export class WizbiiProfessionWebservice {
  readonly #algoliaClientProvider = inject(AlgoliaClientProvider);
  readonly #http = inject(HttpClient);

  #getClient() {
    return this.#algoliaClientProvider.getClient(environment.algolia.clientName)!;
  }

  getInterests(): Observable<Partial<Record<ProfessionInterestsEnum, string>>> {
    return this.#getFilterOptions('interests.value', PROFESSION_INTERESTS);
  }

  getDomains(): Observable<Partial<Record<ProfessionBusinessLineEnum, string>>> {
    return this.#getFilterOptions('businessLines.value', PROFESSIONS_BUSINESS_LINE);
  }

  getStatus(): Observable<Record<string, string>> {
    return this.#getClient().pipe(
      switchMap((client) =>
        from(
          client.searchForFacets({
            requests: [
              {
                indexName: environment.algolia.index.wizbiiProfessions,
                type: 'facet',
                facet: 'professionalStatus.label',
                query: '',
              },
            ],
          })
        )
      ),
      map((response) => response.results[0]),
      map((status) =>
        status.facetHits.reduce(
          (acc, { value }) => ({ ...acc, [value]: UtilsHelper.capitalizeFirstLetter(value) }),
          {} satisfies Record<string, string>
        )
      )
    );
  }

  getAccessLevels(): Observable<Partial<Record<ProfessionAccessLevelEnum, string>>> {
    return this.#getFilterOptions('minimumAccessLevel.value', PROFESSION_ACCESS_LEVEL);
  }

  search(
    query: string | null,
    searchParams?: Pick<
      SearchParamsObject,
      'page' | 'hitsPerPage' | 'facetFilters' | 'aroundLatLng' | 'aroundRadius' | 'filters'
    >
  ): Observable<SearchResponse<ProfessionAlgolia>> {
    return this.#getClient().pipe(
      switchMap((client) =>
        from(
          client.searchForHits<ProfessionAlgolia>({
            requests: [
              {
                indexName: environment.algolia.index.wizbiiProfessions,
                query: query ?? '',
                ...searchParams,
              },
            ],
          })
        )
      ),
      map((response) => response.results[0])
    );
  }

  #getFilterOptions<T>(facetName: string, displayProfessionsFilter: T): Observable<Partial<RecordWithStringKeys<T>>> {
    return this.#getClient().pipe(
      switchMap((client) =>
        from(
          client.searchForFacets({
            requests: [
              {
                indexName: environment.algolia.index.wizbiiProfessions,
                type: 'facet',
                facet: facetName,
                query: '',
              },
            ],
          })
        )
      ),
      map((response) => response.results[0]),
      map((results) =>
        this.#buildFiltersValue<T>(
          results.facetHits.map((facet) => facet.value as Extract<keyof T, string>),
          displayProfessionsFilter
        )
      )
    );
  }

  #buildFiltersValue<T>(
    filtersAlgoliaValue: Extract<keyof T, string>[],
    displayProfessionsFilter: T
  ): Partial<RecordWithStringKeys<T>> {
    const filtersValue: Partial<RecordWithStringKeys<T>> = {};

    return filtersAlgoliaValue.reduce((filtersValue, value) => {
      const displayedFilter = displayProfessionsFilter[value];
      if (displayedFilter && typeof displayedFilter === 'string') {
        return { ...filtersValue, [value]: displayedFilter };
      }
      return filtersValue;
    }, filtersValue);
  }

  getProfession(professionId: string): Observable<Profession> {
    return this.#http.get<Profession>(`${environment.api.formation}/v1/jobCards/${professionId}`).pipe(
      map((profession) => {
        const manTitle = UtilsHelper.capitalizeFirstLetter(profession.manTitle);
        const womanTitle = UtilsHelper.capitalizeFirstLetter(profession.womanTitle);
        return {
          ...profession,
          title: manTitle === womanTitle ? manTitle : `${manTitle} / ${womanTitle}`,
          interests: profession.interests.map((interest) => ({
            ...interest,
            label: UtilsHelper.capitalizeFirstLetter(interest.label),
          })),
        };
      })
    );
  }
}
