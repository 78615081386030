export enum ProfessionInterestsEnum {
  Enqueter_analyser_l_information_je_veux_en_faire_mon_travail = 'T-IDEO2.4819',
  J_ai_la_bosse_du_commerce = 'T-IDEO2.4811',
  J_ai_le_sens_du_contact = 'T-IDEO2.4817',
  J_aime_bouger = 'T-IDEO2.4809',
  J_aime_faire_des_experiences = 'T-IDEO2.4813',
  J_aime_jongler_avec_les_chiffres = 'T-IDEO2.4816',
  J_aime_la_nature = 'T-IDEO2.4824',
  J_aime_les_sensations_fortes = 'T-IDEO2.4826',
  J_aime_organiser_gerer = 'T-IDEO2.4814',
  J_aimerais_informer_communiquer = 'T-IDEO2.4821',
  J_aimerais_travailler_dehors = 'T-IDEO2.4823',
  Je_reve_de_travailler_al_etranger = 'T-IDEO2.4810',
  Je_reve_d_un_metier_artistique = 'T-IDEO2.4829',
  Je_suis_branche_high_tech = 'T-IDEO2.4825',
  Je_suis_fort_en_langues = 'T-IDEO2.4818',
  Je_veux_etre_aux_commandes = 'T-IDEO2.4820',
  Je_veux_etre_utile_aux_autres = 'T-IDEO2.4812',
  Je_veux_faire_respecter_la_loi = 'T-IDEO2.4808',
  Je_veux_m_occuper_d_animaux = 'T-IDEO2.4828',
  Je_veux_m_occuper_d_enfants = 'T-IDEO2.4815',
  Je_veux_proteger_la_planete = 'T-IDEO2.4805',
  Je_veux_travailler_de_mes_mains = 'T-IDEO2.4806',
  Le_sport_est_ma_passion = 'T-IDEO2.4827',
  Ma_vocation_est_de_soigner = 'T-IDEO2.4807',
  Reparer_bricoler_j_adore_ca = 'T-IDEO2.4822',
}

export const PROFESSION_INTERESTS: Record<ProfessionInterestsEnum, string> = {
  [ProfessionInterestsEnum.Enqueter_analyser_l_information_je_veux_en_faire_mon_travail]:
    'Enquêter, analyser l’information, je veux en faire mon travail',
  [ProfessionInterestsEnum.J_ai_la_bosse_du_commerce]: 'J’ai la bosse du commerce',
  [ProfessionInterestsEnum.J_ai_le_sens_du_contact]: 'J’ai le sens du contact',
  [ProfessionInterestsEnum.J_aime_bouger]: 'J’aime bouger',
  [ProfessionInterestsEnum.J_aime_faire_des_experiences]: 'J’aime faire des expériences',
  [ProfessionInterestsEnum.J_aime_jongler_avec_les_chiffres]: 'J’aime jongler avec les chiffres',
  [ProfessionInterestsEnum.J_aime_la_nature]: 'J’aime la nature',
  [ProfessionInterestsEnum.J_aime_les_sensations_fortes]: 'J’aime les sensations fortes',
  [ProfessionInterestsEnum.J_aime_organiser_gerer]: 'J’aime organiser, gérer',
  [ProfessionInterestsEnum.J_aimerais_informer_communiquer]: 'J’aimerais informer, communiquer',
  [ProfessionInterestsEnum.J_aimerais_travailler_dehors]: 'J’aimerais travailler dehors',
  [ProfessionInterestsEnum.Je_reve_de_travailler_al_etranger]: 'Je rêve de travailler à l’étranger',
  [ProfessionInterestsEnum.Je_reve_d_un_metier_artistique]: 'Je rêve d’un métier artistique',
  [ProfessionInterestsEnum.Je_suis_branche_high_tech]: 'Je suis branché high tech',
  [ProfessionInterestsEnum.Je_suis_fort_en_langues]: 'Je suis fort en langues',
  [ProfessionInterestsEnum.Je_veux_etre_aux_commandes]: 'Je veux être aux commandes',
  [ProfessionInterestsEnum.Je_veux_etre_utile_aux_autres]: 'Je veux être utile aux autres',
  [ProfessionInterestsEnum.Je_veux_faire_respecter_la_loi]: 'Je veux faire respecter la loi',
  [ProfessionInterestsEnum.Je_veux_m_occuper_d_animaux]: 'Je veux m’occuper d’animaux',
  [ProfessionInterestsEnum.Je_veux_m_occuper_d_enfants]: 'Je veux m’occuper d’enfants',
  [ProfessionInterestsEnum.Je_veux_proteger_la_planete]: 'Je veux protéger la planète',
  [ProfessionInterestsEnum.Je_veux_travailler_de_mes_mains]: 'Je veux travailler de mes mains',
  [ProfessionInterestsEnum.Le_sport_est_ma_passion]: 'Le sport est ma passion',
  [ProfessionInterestsEnum.Ma_vocation_est_de_soigner]: 'Ma vocation est de soigner',
  [ProfessionInterestsEnum.Reparer_bricoler_j_adore_ca]: 'Réparer, bricoler, j’adore ca',
};

export enum ProfessionBusinessLineEnum {
  Agriculture = 'T-IDEO2.4852',
  Agroequipement = 'T-IDEO2.4849',
  Architecture = 'T-IDEO2.4836',
  Artisanat_d_art = 'T-IDEO2.4830',
  Arts_du_spectacle = 'T-IDEO2.4850',
  Audiovisuel = 'T-IDEO2.4838',
  Audit_conseil = 'T-IDEO2.4854',
  Automobile = 'T-IDEO2.4859',
  Banque_assurances = 'T-IDEO2.4851',
  Batiment_et_travaux_publics_btp = 'T-IDEO2.4863',
  Bien_etre = 'T-IDEO2.4871',
  Commerce_et_distribution = 'T-IDEO2.4865',
  Comptabilite_gestion_ressources_humaines = 'T-IDEO2.4842',
  Construction_aeronautique_ferroviaire_et_navale = 'T-IDEO2.4855',
  Culture_patrimoine = 'T-IDEO2.4840',
  Defense = 'T-IDEO2.4839',
  Droit_et_justice = 'T-IDEO2.4866',
  Edition_librairie_bibliotheque = 'T-IDEO2.4858',
  Electronique = 'T-IDEO2.4861',
  Energie = 'T-IDEO2.4848',
  Enseignement = 'T-IDEO2.4833',
  Environnement = 'T-IDEO2.4857',
  Filiere_bois = 'T-IDEO2.4860',
  Fonction_publique = 'T-IDEO2.4846',
  Hotellerie_restauration = 'T-IDEO2.4837',
  Industrie_alimentaire = 'T-IDEO2.4868',
  Industrie_chimique = 'T-IDEO2.4843',
  Industrie_pharmaceutique = 'T-IDEO2.4832',
  Informatique_internet_et_telecoms = 'T-IDEO2.4835',
  Logistique_et_transport = 'T-IDEO2.4841',
  Maintenance_entretien = 'T-IDEO2.4831',
  Marketing_publicite = 'T-IDEO2.4864',
  Mecanique = 'T-IDEO2.4867',
  Mode_et_industrie_textile = 'T-IDEO2.4844',
  Papiers_cartons = 'T-IDEO2.4853',
  Recherche = 'T-IDEO2.4845',
  Sante = 'T-IDEO2.4869',
  Securite = 'T-IDEO2.4847',
  Social = 'T-IDEO2.4856',
  Sport = 'T-IDEO2.4862',
  Tourisme = 'T-IDEO2.4870',
  Verre_beton_ceramique = 'T-IDEO2.4834',
}

export const PROFESSIONS_BUSINESS_LINE: Record<ProfessionBusinessLineEnum, string> = {
  [ProfessionBusinessLineEnum.Agriculture]: 'Agriculture',
  [ProfessionBusinessLineEnum.Agroequipement]: 'Agroéquipement',
  [ProfessionBusinessLineEnum.Architecture]: 'Architecture',
  [ProfessionBusinessLineEnum.Artisanat_d_art]: 'Artisanat d’art',
  [ProfessionBusinessLineEnum.Arts_du_spectacle]: 'Arts du spectacle',
  [ProfessionBusinessLineEnum.Audiovisuel]: 'Audiovisuel',
  [ProfessionBusinessLineEnum.Audit_conseil]: 'Audit - Conseil',
  [ProfessionBusinessLineEnum.Automobile]: 'Automobile',
  [ProfessionBusinessLineEnum.Banque_assurances]: 'Banque - Assurances',
  [ProfessionBusinessLineEnum.Batiment_et_travaux_publics_btp]: 'Bâtiment et travaux publics (BTP)',
  [ProfessionBusinessLineEnum.Bien_etre]: 'Bien-être',
  [ProfessionBusinessLineEnum.Commerce_et_distribution]: 'Commerce et distribution',
  [ProfessionBusinessLineEnum.Comptabilite_gestion_ressources_humaines]: 'Comptabilité, gestion, ressources humaines',
  [ProfessionBusinessLineEnum.Construction_aeronautique_ferroviaire_et_navale]:
    'Construction aéronautique, ferroviaire et navale',
  [ProfessionBusinessLineEnum.Culture_patrimoine]: 'Culture - Patrimoine',
  [ProfessionBusinessLineEnum.Defense]: 'Défense',
  [ProfessionBusinessLineEnum.Droit_et_justice]: 'Droit et justice',
  [ProfessionBusinessLineEnum.Edition_librairie_bibliotheque]: 'Edition, librairie, bibliothèque',
  [ProfessionBusinessLineEnum.Electronique]: 'Electronique',
  [ProfessionBusinessLineEnum.Energie]: 'Energie',
  [ProfessionBusinessLineEnum.Enseignement]: 'Enseignement',
  [ProfessionBusinessLineEnum.Environnement]: 'Environnement',
  [ProfessionBusinessLineEnum.Filiere_bois]: 'Filiere bois',
  [ProfessionBusinessLineEnum.Fonction_publique]: 'Fonction publique',
  [ProfessionBusinessLineEnum.Hotellerie_restauration]: 'Hôtellerie - Restauration',
  [ProfessionBusinessLineEnum.Industrie_alimentaire]: 'Industrie alimentaire',
  [ProfessionBusinessLineEnum.Industrie_chimique]: 'Industrie chimique',
  [ProfessionBusinessLineEnum.Industrie_pharmaceutique]: 'Industrie pharmaceutique',
  [ProfessionBusinessLineEnum.Informatique_internet_et_telecoms]: 'Informatique, internet et télécoms',
  [ProfessionBusinessLineEnum.Logistique_et_transport]: 'Logistique et transport',
  [ProfessionBusinessLineEnum.Maintenance_entretien]: 'Maintenance, entretien',
  [ProfessionBusinessLineEnum.Marketing_publicite]: 'Marketing, publicité',
  [ProfessionBusinessLineEnum.Mecanique]: 'Mécanique',
  [ProfessionBusinessLineEnum.Mode_et_industrie_textile]: 'Mode et industrie textile',
  [ProfessionBusinessLineEnum.Papiers_cartons]: 'Papiers Cartons',
  [ProfessionBusinessLineEnum.Recherche]: 'Recherche',
  [ProfessionBusinessLineEnum.Sante]: 'Santé',
  [ProfessionBusinessLineEnum.Securite]: 'Sécurité',
  [ProfessionBusinessLineEnum.Social]: 'Social',
  [ProfessionBusinessLineEnum.Sport]: 'Sport',
  [ProfessionBusinessLineEnum.Tourisme]: 'Tourisme',
  [ProfessionBusinessLineEnum.Verre_beton_ceramique]: 'Verre, béton, céramique',
};
export enum ProfessionAccessLevelEnum {
  Cap_ou_equivalent1_an = 'REF.404',
  Premiere = 'REF.405',
  Troisieme = 'REF.406',
  Quatrieme = 'REF.407',
  Diplome_national_du_brevet = 'REF.408',
  Terminale = 'REF.409',
  Bac5 = 'REF.410',
  Bac2 = 'REF.411',
  Bac_ou_equivalent = 'REF.412',
  Cap_ou_equivalent = 'REF.413',
  Sans_diplome = 'REF.414',
  Autre_formation = 'REF.415',
  Non_renseigne = 'REF.416',
  Cinquieme = 'REF.417',
  Sixieme = 'REF.418',
  Seconde = 'REF.419',
  Bac1 = 'REF.420',
  Bac3 = 'REF.421',
  Bac4 = 'REF.422',
  Bac6 = 'REF.423',
  Bac7 = 'REF.424',
  Bac8 = 'REF.425',
  Bac9_et_plus = 'REF.426',
}

export const PROFESSION_ACCESS_LEVEL: Record<ProfessionAccessLevelEnum, string> = {
  [ProfessionAccessLevelEnum.Cap_ou_equivalent1_an]: 'Cap ou équivalent + 1 an',
  [ProfessionAccessLevelEnum.Premiere]: '1ère',
  [ProfessionAccessLevelEnum.Troisieme]: '3ème',
  [ProfessionAccessLevelEnum.Quatrieme]: '4ème',
  [ProfessionAccessLevelEnum.Diplome_national_du_brevet]: 'Diplôme national du brevet',
  [ProfessionAccessLevelEnum.Terminale]: 'Terminale',
  [ProfessionAccessLevelEnum.Bac5]: 'Bac + 5',
  [ProfessionAccessLevelEnum.Bac2]: 'Bac + 2',
  [ProfessionAccessLevelEnum.Bac_ou_equivalent]: 'Bac ou équivalent',
  [ProfessionAccessLevelEnum.Cap_ou_equivalent]: 'CAP ou équivalent',
  [ProfessionAccessLevelEnum.Sans_diplome]: 'Sans diplôme',
  [ProfessionAccessLevelEnum.Autre_formation]: 'Autre formation',
  [ProfessionAccessLevelEnum.Non_renseigne]: 'Non renseigné',
  [ProfessionAccessLevelEnum.Cinquieme]: '5ème',
  [ProfessionAccessLevelEnum.Sixieme]: '6ème',
  [ProfessionAccessLevelEnum.Seconde]: 'Seconde',
  [ProfessionAccessLevelEnum.Bac1]: 'Bac + 1',
  [ProfessionAccessLevelEnum.Bac3]: 'Bac + 3',
  [ProfessionAccessLevelEnum.Bac4]: 'Bac + 4',
  [ProfessionAccessLevelEnum.Bac6]: 'Bac + 6',
  [ProfessionAccessLevelEnum.Bac7]: 'Bac + 7',
  [ProfessionAccessLevelEnum.Bac8]: 'Bac + 8',
  [ProfessionAccessLevelEnum.Bac9_et_plus]: 'Bac + 9 et plus',
};
